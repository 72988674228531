<template>
  <div class="pro-des">
    <van-swipe v-if="Alldata.pictureUrl" indicator-color="white">
      <van-swipe-item v-for="(picItem,picInx) in Alldata.pictureUrl.split(',')" :key="picInx">
        <div class="swiper-imgs"><img :src="picItem" /></div>
      </van-swipe-item>
    </van-swipe>
    <van-swipe v-if="!Alldata.pictureUrl" indicator-color="white">
      <van-swipe-item class="">
        <div class="swiper-imgs"><img src="../../assets/images/product.png" /></div>
      </van-swipe-item>
    </van-swipe>
    <div class="pro-title">{{Alldata.gardenName}}</div>
    <van-cell-group inset>
      <van-cell title="园区总纳税额（万元）: " :value="Alldata.completeTaxAmountW" />
      <van-cell title="入驻纳税额（万元）: " :value="Alldata.taxableAmountW" />
      <van-cell title="剩余纳税额（万元）: " :value="Alldata.remainingTaxAmountW" />
      <van-cell title="企业入驻名额（个）: " :value="Alldata.inNumber" />
      <van-cell title="已入驻（个）: " :value="Alldata.registeredNumber" />
      <van-cell title="剩余名额（个）: " :value="Alldata.remainingNumber" />
      <!-- <van-cell title="园区简介: ">
        <template>
          <a v-if="Alldata.profile" :href="Alldata.profile" target="_brank">查看</a>
        </template>
      </van-cell> -->
      <van-collapse v-model="activeNames" accordion>
          <van-collapse-item
              title="园区简介"
              :value="''" 
              name="1">
              <!-- <template #title>
                  <div class="notice-line"><van-tag class="notice-tags" :type="item.noticeType==1?'primary' : 'warning'">{{item.noticeType == 1 ? '通知':'公告'}}</van-tag><div class="webkit-box-line-clamp-orient">{{item.noticeTitle}}</div></div>
              </template> -->
              <div v-html="Alldata.profile"></div>
          </van-collapse-item>
      </van-collapse>
    </van-cell-group>
  </div>
</template>

<script>
import { findById } from "@/api/order";
export default {
  filters:{
  },
  data() {
    return {
      activeNames:[],
      orderId: "",
      Alldata: {},
    };
  },
  computed: {},
  methods: {
    //   通过id查询订单信息
    async findAll() {
      let res = await findById(this.orderId);
      this.Alldata = res.data;
    },
  },
  created() {
    this.orderId = this.$route.query.id;
    this.findAll();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.pro-title {
    font-size: 20px;
    padding:20px 10px;
    color:#999;
    background: #fff;
}
</style>